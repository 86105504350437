//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapMutations, mapState } from "vuex";
import Container from "~/components/common/Container.vue";
import CategoryCatalog from "~/components/CategoryCatalog.vue";
import { isIndexPage } from "~/service/catalog-router/helpers";

export default {
    name: "Mainsection",
    components: { Container, CategoryCatalog },
    layout: "catalog",
    data() {
        return { mobileBannerOpened: false };
    },
    computed: {
        ...mapState("products", ["products", "viewOpacity", "isSearchHide"]),
        ...mapGetters("products", ["getCategories"]),
        ...mapState("account", ["showMobileAppBanner"]),
        ...mapGetters("delivery", ["showPanelSelectDelivery"]),

        categoriesCatalog() {
            return this.getCategories.filter((category) => !category.featured);
        },
        routeIsCatalog() {
            return isIndexPage(this.$router.currentRoute);
        },
    },
    mounted() {
        this.$store.commit("products/setViewOpacity", 0);
        this.checkGetParams("password-recovery");
        this.handleSearchVisability();
        this.setSearchHide(false);
    },
    methods: {
        ...mapMutations("products", ["setSearchHide"]),
        checkGetParams(param) {
            const urlString = window.location.href;
            const url = new URL(urlString);
            const hasParam = url.search === `?${param}`;
            if (hasParam) {
                this.$store.commit("account/setPasswordRecoveryLink", true);
            } else {
                this.$store.commit("account/setPasswordRecoveryLink", false);
            }
        },
        handleSearchVisability() {
            if (this.routeIsCatalog) {
                document.addEventListener("scroll", (e) => {
                    const isMob = window.matchMedia("(max-width: 1024px)").matches;
                    const scrolled = window.scrollY || document.documentElement.scrollTop;

                    if ((scrolled <= 100 && !this.showPanelSelectDelivery) || scrolled <= 180) {
                        this.setSearchHide(false);
                    } else if (isMob) {
                        const dY = scrolled - this.currentScrollPosition;

                        if (Math.abs(dY) < 50) {
                            this.setSearchHide(dY > 0);
                        }

                        this.currentScrollPosition = scrolled;
                    }
                });
            }
        },
    },
};
