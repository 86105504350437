import { render, staticRenderFns } from "./index.vue?vue&type=template&id=1e343712&scoped=true&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"
import style0 from "./index.vue?vue&type=style&index=0&id=1e343712&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1e343712",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MainBannerSlider: require('/usr/src/app/components/MainBannerSlider.vue').default,CurrentCategory: require('/usr/src/app/components/functionality/CurrentCategory.vue').default,ProductPopup: require('/usr/src/app/components/sections/ProductPopup.vue').default,MenuCategory: require('/usr/src/app/components/products/MenuCategory.vue').default,CategoryCatalog: require('/usr/src/app/components/CategoryCatalog.vue').default,Container: require('/usr/src/app/components/common/Container.vue').default,HeaderAnimation: require('/usr/src/app/components/functionality/HeaderAnimation.vue').default})
